import { buildIndex } from '@adalo/utils'
import { adaloBackendAxios } from 'utils/io/http/axios'

const FETCH_TCI_ORGANIZATIONS = 'FETCH_TCI_ORGANIZATIONS'
const FETCH_TCI_ORGANIZATION_USERS = 'FETCH_TCI_ORGANIZATION_USERS'
const DOWNGRADE_TCI_ORGANIZATION = 'DOWNGRADE_TCI_ORGANIZATION'
const UPGRADE_TCI_ORGANIZATION = 'UPGRADE_TCI_ORGANIZATION'

export const INITIAL_STATE = {
  organization: {},
  users: [],
  organizations: {},
  currentOrganization: null,
  currentOrganizationId: null,
}

export default (state = INITIAL_STATE, action) => {
  if (action?.type === `${FETCH_TCI_ORGANIZATIONS}_FULFILLED`) {
    const organizations = buildIndex(action.payload?.data || [], org => org.id)

    return {
      ...state,
      organizations,
    }
  }

  if (action?.type === `${FETCH_TCI_ORGANIZATION_USERS}_FULFILLED`) {
    const organization = action.payload?.data?.organization
    const users =
      action.payload?.data?.users.map(user => ({
        ...user,
        isAdmin: user?.OrganizationUsers[0]?.isAdmin,
      })) || []

    return {
      ...state,
      organization,
      users,
    }
  }

  if (action?.type === `${DOWNGRADE_TCI_ORGANIZATION}_FULFILLED`) {
    const organizationId = action?.payload?.data?.id
    const organizations = state.organizations

    organizations[organizationId] = {
      ...organizations[organizationId],
      active: false,
    }

    return {
      ...state,
      organizations,
    }
  }

  if (action?.type === `${UPGRADE_TCI_ORGANIZATION}_FULFILLED`) {
    const organizationId = action?.payload?.data?.id
    const organizations = state.organizations

    organizations[organizationId] = {
      ...organizations[organizationId],
      active: true,
    }

    return {
      ...state,
      organizations,
    }
  }

  return state
}

export const fetchTCIOrganizations = () => ({
  type: FETCH_TCI_ORGANIZATIONS,
  payload: adaloBackendAxios.get(`/tci/organizations`),
})

export const fetchTCIOrganizationUsers = id => ({
  type: FETCH_TCI_ORGANIZATION_USERS,
  payload: adaloBackendAxios.get(`/tci/organizations/${id}/users`),
})

export const downgradeTCIOrganization = id => ({
  type: DOWNGRADE_TCI_ORGANIZATION,
  payload: adaloBackendAxios.put(`/tci/organizations/${id}/downgrade`),
})

export const upgradeTCIOrganization = id => ({
  type: UPGRADE_TCI_ORGANIZATION,
  payload: adaloBackendAxios.put(`/tci/organizations/${id}/upgrade`),
})

export const getOrganizations = state => {
  const organizations = Object.values(state?.tci?.organizations || {})

  return organizations
}

export const getOrganizationUsers = state => {
  const organization = state?.tci?.organization || {}
  const users = state?.tci?.users || []

  return { organization, users }
}
