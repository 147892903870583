import { useRef, useEffect, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual as _isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { RouteComponentProps } from 'react-router-dom'
import classNames from 'classnames'

import { formatDate } from 'utils/datetime'
import { fetchTCIOrganizationUsers, getOrganizationUsers } from 'ducks/tci'
import type { User } from 'ducks/users/index'

import Page from 'components/Shared/Page'

import '../Admin/Admin.scss'

interface ItemParams {
  user: User
}

interface TCIState {
  organization: {
    name: string
    id: string
    createdAt: string
    updatedAt: string
  }
  users: Array<User>
}

const UserItem = ({ user }: ItemParams): ReactElement => {
  const { t } = useTranslation()
  const role = user?.isAdmin ? 'admin' : 'member'

  return (
    <Link className="admin-user-item" to={`/admin/users/${user.id}`}>
      <h5>{user.name}</h5>
      <p>
        <span className={classNames('admin-role-status', `admin-role-${role}`)}>
          {t(role)}
        </span>
      </p>
      <p>{user.email}</p>
      <p>{formatDate(user.createdAt)}</p>
    </Link>
  )
}
const OrganizationUsers = ({
  match,
}: RouteComponentProps<{ orgId: string }>): ReactElement => {
  const organizationId = match?.params?.orgId
  const mounted = useRef<boolean>(false)
  const dispatch = useDispatch()
  const { organization, users } = useSelector(
    state => getOrganizationUsers(state) as unknown as TCIState,
    _isEqual
  )
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchTCIOrganizationUsers(organizationId))
    mounted.current = true
  }, [])

  return (
    <Page className="admin-users-page" title={organization?.name}>
      <section className="users-header">
        <h1>{organization?.name}</h1>
      </section>
      <div className="admin-users-header">
        <p>{t('Name')}</p>
        <p>{t('Role')}</p>
        <p>{t('Email')}</p>
        <p>{t('Signup Date')}</p>
      </div>
      {users.map(u => (
        <UserItem key={u.id} user={u} />
      ))}
    </Page>
  )
}

export default OrganizationUsers
