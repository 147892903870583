/* eslint-disable no-alert */
import React, { useRef, useEffect, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual as _isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { formatDate } from 'utils/datetime'

import Page from 'components/Shared/Page'

import {
  downgradeTCIOrganization,
  fetchTCIOrganizations,
  getOrganizations,
  upgradeTCIOrganization,
} from 'ducks/tci'

import '../Admin/Admin.scss'

interface Organization {
  name: string
  Users: Array<{ id: string; email: string }>
  active: string
  id: string
  createdAt: string
  updatedAt: string
  OrganizationUsers: Array<{
    User: { id: string; email: string }
  } | null>
}

interface ItemParams {
  organization: Organization
}

const ActionButton = ({
  organizationId,
  paying,
}: {
  organizationId: string
  paying: boolean
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const downgradeOrganization = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault()

    const confirmed = window.confirm(
      t('Are you sure you want to downgrade the organization?')
    )

    if (confirmed) dispatch(downgradeTCIOrganization(organizationId))
  }

  const upgradeOrganization = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault()

    const confirmed = window.confirm(
      t('Are you sure you want to upgrade the organization?')
    )

    if (confirmed) dispatch(upgradeTCIOrganization(organizationId))
  }

  if (paying) {
    return (
      <input
        className="admin-action-button admin-action-button--downgrade"
        type="button"
        value={t('Downgrade')}
        onClick={downgradeOrganization}
      />
    )
  }

  return (
    <input
      className="admin-action-button admin-action-button--upgrade"
      type="button"
      value={t('Upgrade')}
      onClick={upgradeOrganization}
    />
  )
}

const OrganizationItem = ({ organization }: ItemParams): ReactElement => {
  const { t } = useTranslation()
  const orgStatus = organization.active ? 'paying' : 'free'
  const [user] = organization?.OrganizationUsers
  const admin = user?.User

  return (
    <Link
      className="admin-user-item"
      to={`/tci/organizations/${organization.id}`}
    >
      <p>{organization.name}</p>
      <p>
        <span
          className={classNames('admin-org-status', `admin-org-${orgStatus}`)}
        >
          {t(orgStatus)}
        </span>
      </p>
      <p>{admin?.email}</p>
      <span>
        <ActionButton
          organizationId={organization.id}
          paying={Boolean(organization.active)}
        />
      </span>
      <p>{formatDate(organization.createdAt)}</p>
      <p>{formatDate(organization.updatedAt)}</p>
    </Link>
  )
}

const TCIOrganizations = (): ReactElement => {
  const mounted = useRef<boolean>(false)
  const dispatch = useDispatch()
  const organizations = useSelector(
    state => getOrganizations(state) as unknown as Organization[],
    _isEqual
  )
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchTCIOrganizations())
    mounted.current = true
  }, [])

  return (
    <Page className="admin-users-page" title={t('Organizations')}>
      <section className="users-header">
        <h1>{t('Organizations')}</h1>
      </section>
      <div className="admin-users-header">
        <p>{t('Name')}</p>
        <p>{t('Status')}</p>
        <p>{t('Owner')}</p>
        <p>{t('Action')}</p>
        <p>{t('Created At')}</p>
        <p>{t('Updated At')}</p>
      </div>
      {organizations.map(org => (
        <OrganizationItem key={org.id} organization={org} />
      ))}
    </Page>
  )
}

export default TCIOrganizations
