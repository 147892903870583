import React, { useEffect } from 'react'
import classNames from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { setDocumentTitle } from 'utils/browsers'

import Modal from './Modal'
import './Page.css'

interface PageProps extends RouteComponentProps {
  baseURL?: string
  children: React.ReactNode
  className?: string
  modalContent?: React.ReactNode
  modalProps?: Record<string, unknown>
  form?: boolean
  title?: string
}

const Page: React.FC<PageProps> = ({
  baseURL,
  history,
  children,
  className,
  modalContent,
  modalProps,
  form,
  title,
}) => {
  useEffect(() => {
    if (title) {
      setDocumentTitle(title)
    }
  }, [title])

  const handleModalClose = () => {
    if (!baseURL) {
      return
    }

    history.push(baseURL)
  }

  return (
    <div className={classNames('page', className, { 'form-page': form })}>
      {children}
      {modalContent && (
        <Modal {...modalProps} onClose={handleModalClose}>
          {modalContent}
        </Modal>
      )}
    </div>
  )
}

export default withRouter(Page)
