import i18n, { TFunction, InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const configs: InitOptions = {
  supportedLngs: ['en', 'ja'],
  nonExplicitSupportedLngs: true,
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    caches: ['cookie', 'localStorage'],
  },
  backend: {
    loadPath: '/locales/{{lng}}/translation.json',
    crossDomain: true,
  },
}

console.log('Initializing i18n with config:', configs)

export const init = async (): Promise<TFunction> => {
  try {
    const t = await i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(configs)

    i18n.on('languageChanged', lng => {
      console.log('Language changed to:', lng)
    })

    i18n.on('loaded', loaded => {
      console.log('Translations loaded:', loaded)
    })

    i18n.on('failedLoading', (lng, ns, msg) => {
      console.error('Failed loading translations:', { lng, ns, msg })
    })

    console.log('i18n initialized successfully')
    console.log('Current language:', i18n.language)
    console.log('Available languages:', i18n.languages)
    console.log('Loaded namespaces:', i18n.options.ns)

    return t
  } catch (error) {
    console.error('i18n initialization failed:', error)
    throw error
  }
}

export const instance = i18n
