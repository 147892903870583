import React, { FormEventHandler } from 'react'
import {
  reduxForm,
  Field,
  change,
  InjectedFormProps,
  FormSubmitHandler,
  DecoratedFormProps,
  formValueSelector,
  getFormInitialValues,
} from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import { App, getApp, updateApp } from 'ducks/apps'
import { State } from 'ducks/editor/objects'
import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import { Button } from 'components/Shared/NewModal'
import { DeepLinking } from 'ducks/apps/App'
import MenuControl from '../Inspect/Libraries/MenuControl'

import './DeepLinking.scss'

const FORM_NAME = 'deepLinkingForm'
type FormValues = {
  iosFallbackURL: string
  androidFallbackURL: string
  fallbackToPWA: boolean
}

type DeepLinkingProps = InjectedFormProps<FormValues, DeepLinkingProps> & {
  appId: string
  handleSubmit: (
    submit: FormSubmitHandler<FormValues, DeepLinkingProps, string>,
    props?:
      | DecoratedFormProps<FormValues, DeepLinkingProps, string>
      | undefined,
    valid?: boolean | undefined,
    asyncValidate?: unknown,
    fields?: string[] | undefined
  ) => FormEventHandler<HTMLFormElement> | undefined
  deepLinking: DeepLinking
  fallbackToPWA: boolean
  isSaveEnabled: boolean
}

const validate = (values: {
  iosFallbackURL: string
  androidFallbackURL: string
}) => {
  const errors: { iosFallbackURL?: string; androidFallbackURL?: string } = {}

  const urlRegex =
    /^(https?:\/\/)(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/i

  if (values.iosFallbackURL && !urlRegex.test(values.iosFallbackURL)) {
    errors.iosFallbackURL = 'Invalid URL format. Example: https://example.com'
  }

  if (values.androidFallbackURL && !urlRegex.test(values.androidFallbackURL)) {
    errors.androidFallbackURL =
      'Invalid URL format. Example: https://example.com'
  }

  return errors
}

const label = 'Where to redirect users when the app is not installed?'

const options = [
  { label: 'Redirect to PWA', value: true },
  { label: 'Custom Redirect', value: false },
]

const DeepLinkingComponent: React.FC<DeepLinkingProps> = ({
  appId,
  handleSubmit,
  submitting,
  deepLinking,
  fallbackToPWA,
  isSaveEnabled,
  invalid,
}) => {
  const dispatch = useDispatch()

  const submitFunction: FormSubmitHandler<
    FormValues,
    DeepLinkingProps
  > = async formValues => {
    await new Promise(resolve => setTimeout(resolve, 200))
    dispatch(
      updateApp(appId, {
        deepLinking: {
          ...deepLinking,
          iosFallbackURL: formValues.iosFallbackURL,
          androidFallbackURL: formValues.androidFallbackURL,
          fallbackToPWA: formValues.fallbackToPWA,
        },
      })
    )
  }

  const onChange = ({ layout }: { layout: string }): void => {
    dispatch(change(FORM_NAME, 'fallbackToPWA', layout))
  }

  return (
    <form
      autoComplete="off"
      className="deep-linking-form"
      onSubmit={
        handleSubmit(submitFunction) as FormEventHandler<HTMLFormElement>
      }
    >
      <div className="redirection-and-fallback">
        <div className="redirection-and-fallback__header">
          <h5 className="redirection-and-fallback__title">
            Redirection and Fallback
          </h5>
        </div>
        <MenuControl
          displayName={label}
          options={options}
          value={fallbackToPWA}
          onChange={onChange}
          name="layout"
        />
        {!fallbackToPWA && (
          <div>
            <Field
              component={SettingsFormField}
              name="iosFallbackURL"
              label="Fallback URL iOS"
              placeholder="https://example.com"
              type="text"
            />
            <Field
              component={SettingsFormField}
              name="androidFallbackURL"
              label="Fallback URL Android"
              placeholder="https://example.com"
              type="text"
            />
          </div>
        )}
      </div>

      <Button
        className="fallback-save-button"
        type="submit"
        alignRight
        disabled={submitting || !isSaveEnabled || invalid}
      >
        Save
      </Button>
    </form>
  )
}

const selector = formValueSelector(FORM_NAME)

function computeIsSaveEnabled(
  currentValues: FormValues,
  initialVals: Partial<FormValues>
): boolean {
  if (!initialVals) return false

  if (currentValues.fallbackToPWA !== initialVals.fallbackToPWA) {
    return true
  }

  if (!currentValues.fallbackToPWA) {
    const changedIos =
      currentValues.iosFallbackURL !== initialVals.iosFallbackURL
    const changedAndroid =
      currentValues.androidFallbackURL !== initialVals.androidFallbackURL

    return changedIos || changedAndroid
  }

  return false
}

const WrappedDeepLinkingComponent = reduxForm<FormValues, DeepLinkingProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(DeepLinkingComponent)

const mapStateToProps = (state: State, ownProps: { appId: string }) => {
  const app: App = getApp(state, ownProps.appId)
  const { deepLinking } = app || {}

  const fallbackToPWA = selector(state, 'fallbackToPWA') as boolean
  const iosFallbackURL = selector(state, 'iosFallbackURL') as string
  const androidFallbackURL = selector(state, 'androidFallbackURL') as string

  const initialFormValues = getFormInitialValues(FORM_NAME)(state) as FormValues

  return {
    appId: ownProps.appId,
    deepLinking,
    fallbackToPWA,
    initialValues: {
      iosFallbackURL: deepLinking?.iosFallbackURL,
      androidFallbackURL: deepLinking?.androidFallbackURL,
      fallbackToPWA: deepLinking?.fallbackToPWA ?? true,
    },
    isSaveEnabled: computeIsSaveEnabled(
      { fallbackToPWA, iosFallbackURL, androidFallbackURL },
      initialFormValues
    ),
  }
}

export default connect(mapStateToProps)(WrappedDeepLinkingComponent)
