import io from 'socket.io-client'
import { getAuthToken } from 'utils/auth'
import { baseWSURL } from 'utils/io/constants'

export const socket = io(baseWSURL, {
  transports: ['websocket', 'polling'],
  query: {
    sessionToken: getAuthToken(),
  },
  autoConnect: false,
})
