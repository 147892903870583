import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isTranscosmosUser } from 'utils/tci'
import { getCurrentUser, UserState } from 'ducks/users'

import TCIOrganizations from './TCIOrganizations'
import TCIOrganizationUsers from './TCIOrganizationUsers'

const TCIRoutes: React.FC = () => {
  const user = useSelector((state: UserState) => getCurrentUser(state))
  const isTCIAdmin = isTranscosmosUser(user?.email || '', user?.admin)

  if (!isTCIAdmin) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div style={{ marginBottom: '10px', padding: '20px' }}>
        <NavLink className="tci-nav-link" to="/">
          Builder
        </NavLink>
        {` • `}
        <NavLink className="tci-nav-link" to="/tci/organizations">
          TCI Organizations
        </NavLink>
      </div>
      <Switch>
        <Route exact path="/tci/organizations" component={TCIOrganizations} />
        <Route
          exact
          path="/tci/organizations/:orgId"
          component={TCIOrganizationUsers}
        />
      </Switch>
    </>
  )
}

export default TCIRoutes
